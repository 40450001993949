<template>
  <v-card v-if="weather.found" flat color="white">
    <v-list-item>
      <v-list-item-avatar size="70">
        <v-img
          :src="weather.weather_code_image"
          width="50"
          :title="weather.weather_code_description"
          style="filter: brightness(90%)"
        ></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ cityName || "S/F" }},
          {{ formatDate(weather.date) }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ weather.weather_code_description }}
        </v-list-item-subtitle>
        <v-list-item-subtitle class="text-h4 font-weight-normal">
          <div>
            {{ weather.temperature_mean }}
            <span
              style="font-size: 15px; vertical-align: top; margin-left: -5px"
            >
              &deg;C
            </span>
            <v-menu>
              <template #activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="primary" small>
                  <v-icon small>mdi-information</v-icon>
                </v-btn>
              </template>

              <v-card width="350">
                <v-card-title>{{ __("Clima") }}</v-card-title>
                <v-card-subtitle>
                  {{ cityName || "S/F" }},
                  {{ formatDate(weather.date) }}
                </v-card-subtitle>

                <v-card-text>
                  <v-simple-table dense>
                    <tbody>
                      <tr>
                        <th width="120px">{{ __("Descrição") }}:</th>
                        <td>
                          {{ weather.weather_code_description }}
                        </td>
                      </tr>
                      <tr>
                        <th>{{ __("Temp média") }}:</th>
                        <td>
                          {{ weather.temperature_mean }}
                          ºC
                        </td>
                      </tr>
                      <tr>
                        <th>{{ __("Temp mínima") }}:</th>
                        <td>
                          {{ weather.temperature_min }}
                          ºC
                        </td>
                      </tr>
                      <tr>
                        <th>{{ __("Temp máxima") }}:</th>
                        <td>
                          {{ weather.temperature_max }}
                          ºC
                        </td>
                      </tr>
                      <tr>
                        <th>{{ __("Precipitação") }}:</th>
                        <td>
                          {{ weather.precipitation_sum }}
                          mm
                        </td>
                      </tr>
                      <tr>
                        <th>{{ __("Duração da precipitação") }}:</th>
                        <td>
                          {{ weather.precipitation_hours }}
                          h
                        </td>
                      </tr>
                      <tr>
                        <th>{{ __("Neve") }}:</th>
                        <td>
                          {{ weather.snowfall_sum }}
                          mm
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import { formatDate } from "@/tools/date";
import i18n from "@/mixins/i18n";

export default {
  props: {
    weather: Object,
    cityName: String,
  },

  mixins: [i18n],

  data() {
    return {
      formatDate,
      i18n_prefix: "weather",
    };
  },

  methods: {},
};
</script>
